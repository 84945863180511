/*** IMPORTS FROM imports-loader ***/
var define = false;

export default {
  init: (app, gsap, Swiper, Navigation, Thumbs) => {
    /* Carrousel single offre */
    const carrousel_2 = new Swiper(".mySwiper2", {
      slidesPerView: 4,
      watchSlidesProgress: true,
      freeMode: true,
      direction: "vertical",
      spaceBetween: 10,
    });

    const carrousel = new Swiper(".mySwiper", {
      slidesPerView: 1,
      // spaceBetween: 30,
      loop: true,
      autoHeight: true,
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },
      thumbs: {
        swiper: carrousel_2,
      },
    });
  },
};

