/*** IMPORTS FROM imports-loader ***/
var define = false;

export default {
  init: (
    app,
    gsap,
    ScrollTrigger,
    CustomEase,
    LazyLoad,
    //CookieManager,
    Accordion
  ) => {
    /*
        |
        | Constants
        |-----------
        */
    const $window = $(window),
      $body = $("body"),
      $bodyOffset = $body.offset(),
      $preloader = $("#preloader"),
      $cookieBanner = $(".cookie-banner"),
      $cookieAccept = $cookieBanner.find(".item-accept"),
      $cookieRefuse = $cookieBanner.find(".item-refuse"),
      $header = $("header"),
      $burger = $(".burger"),
      $scrollTo = $(".scrollto"),
      $scrollUp = $(".scrollup"),
      $themeElement = $("[data-theme]"),
      $themeColor = $(".theme-color"),
      $colorSpan = $themeColor.find("span");
    /*
        |
        | Easings
        |----------
        */
    CustomEase.create("easeCustom", "M0,0 C0.4,0 0.2,1 1,1");
    CustomEase.create("easeSmooth", "M0,0 C0.19,1 0.22,1 1,1");

    /*
        |
        | Loader
        |---------
        */
    $themeElement.attr("data-theme", sessionStorage.theme);

    if (sessionStorage.getItem("loaded_once") === null) {
      sessionStorage.setItem("loaded_once", "loaded_once");
      $preloader.find(".preloader-full").addClass("active");

      $window.on("load", function () {
        gsap.to($preloader, 0.2, {
          opacity: 0,
          ease: "Linear.easeNone",
          delay: 2.4,
          onComplete: () => $preloader.remove(),
          addCallback: () => app.dispachEvent($body, "loaderEnd"),
        });
      });
    } else {
      $window.on("load", function () {
        gsap.to($preloader, 0.2, {
          opacity: 0,
          ease: "Linear.easeNone",
          onComplete: () => $preloader.remove(),
          addCallback: () => app.dispachEvent($body, "loaderEnd"),
        });
      });
    }

    /*
        |
        | On Window Loaded
        |-----------------
        */
    $body.on("loaderEnd", () => {
      /*
            |
            | LazyLoad
            |-----------------
            */
      function lazyParent(el) {
        const lazyContainer = el.closest(".lazy-container");
        lazyContainer?.classList.add("is-load");
      }

      window.lazy = new LazyLoad({
        unobserve_entered: true,
        callback_loaded: lazyParent,
      });

      /*
            |
            | Burger Menu
            |-----------------
            */
      $burger.on("click", function () {
        $body.toggleClass("is-menu");
      });

      function checkDevice() {
        if ("matchMedia" in window) {
          if (window.matchMedia("(min-width:768px)").matches) {
            $body.removeClass("is-menu");
          }
        }
      }
      window.addEventListener("resize", checkDevice, false);

      /*
            |
            | Header on Scroll
            |
            */
      // const showAnim = gsap
      //   .from($header, {
      //     yPercent: -100,
      //     paused: true,
      //     duration: 0.2,
      //   })
      //   .progress(1);

      // ScrollTrigger.matchMedia({
      //   "(min-width: 64rem)": function () {
      //     ScrollTrigger.create({
      //       start: "top -80",
      //       end: 99999,
      //       onUpdate: (self) => {
      //         self.direction === -1 ? showAnim.play() : showAnim.reverse();
      //       },
      //       toggleClass: { className: "is-scrolled", targets: $header },
      //     });
      //   },
      //   "(max-width: 47rem)": function () {
      //     showAnim.play();
      //   },
      // });

      /*
            |
            | Scrollto
            |-----------------
            */
      $scrollTo.on("click", function () {
        gsap.to(window, {
          duration: 0.8,
          ease: "easeCustom",
          scrollTo: $window.height(),
        });
      });

      /*
            |
            | Scroll to top
            |-----------------
            */
      $scrollUp.on("click", function () {
        gsap.to(window, {
          duration: 1.2,
          ease: "easeCustom",
          scrollTo: $bodyOffset.top,
        });
      });

      /*
            |
            | Switch Theme Color 
            |-----------------
            */
      if (sessionStorage.theme == "dark") {
        $colorSpan.text("Light");
      } else {
        $colorSpan.text("Dark");
      }

      $themeColor.on("click", function () {
        if (sessionStorage.theme != "dark") {
          $themeElement.attr("data-theme", "dark");
          sessionStorage.theme = "dark";
          $colorSpan.text("Light");
        } else {
          $themeElement.attr("data-theme", "light");
          sessionStorage.theme = "light";
          $colorSpan.text("Dark");
        }
      });
    });

    /*
        |
        | Cookie Manager
        |-----------------
        */
    /*new CookieManager($cookieBanner, {
      name: "local_cookie_use",
      duration: 30,
      buttons: {
        accept: $cookieAccept,
        refuse: $cookieRefuse,
        // close: $cookieClose
      },
      onAccept: () => {},
      onRefuse: () => {},
    });*/

    let mm = gsap.matchMedia(),
      breakPoint = 1024;
    let play = document.querySelector(".burger");

    mm.add(
      {
        isDesktop: `(min-width: ${breakPoint}px) and (prefers-reduced-motion: no-preference)`,
        isMobile: `(max-width: ${
          breakPoint - 1
        }px) and (prefers-reduced-motion: no-preference)`,
      },
      (context) => {
        let { isDesktop, isMobile } = context.conditions;
        let tl = gsap.timeline({ paused: true });
        const navFullscreen = document.querySelector("#nav-fullscreen");
        const menu = document.querySelector(".menu");
        const nav = document.querySelector(".nav");
        var navStyle = nav.currentStyle || window.getComputedStyle(nav);
        const x =
          navFullscreen.offsetWidth -
          menu.offsetWidth +
          parseInt(navStyle.paddingLeft);

        if (isDesktop) {
          tl.to(
            menu,
            {
              x: `${-x - 5}px`,
              duration: 0.4,
              delay: 0.04,
              ease: "Power2.easeinOut",
            },
            0
          );
        }

        tl.to(
          navFullscreen,
          {
            x: `0%`,
            duration: 0.4,
            ease: "Power2.easeinOut",
          },
          0
        );

        tl.reversed(true);
        play.addEventListener("click", function () {
          tl.reversed() ? tl.play() : tl.reverse();
        });
      }
    );
  },
};

