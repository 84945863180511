/*** IMPORTS FROM imports-loader ***/
var define = false;

export default {
  init: (app, gsap, Swiper, Navigation) => {
    if (window.matchMedia("(min-width: 1024px)").matches) {
      const swiper_1 = new Swiper(".swiper", {
        slidesPerView: 3,
        spaceBetween: 30,
        centerInsufficientSlides: true,
        // clickable: true,
        watchSlidesProgress: true,
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
      });
      const swiper_2 = new Swiper(".swiper2", {
        slidesPerView: 1,
        spaceBetween: 30,
        // freeMode: true,
        direction: "horizontal",
        allowTouchMove: false,
        thumbs: {
          swiper: swiper_1,
        },
      });
      // console.log(swiper2);
    } else {
      const swiper_1 = new Swiper(".swiper", {
        slidesPerView: 1,
        // clickable: true,
        spaceBetween: 0,
        watchSlidesProgress: true,
        // loop: true,
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
      });
      swiper_1.on("slideChange", () => {
        swiper_2.slideTo(swiper_1.activeIndex);
      });
      const swiper_2 = new Swiper(".swiper2", {
        slidesPerView: 1,
        spaceBetween: 30,
        // freeMode: true,
        direction: "horizontal",
        thumbs: {
          swiper: swiper_1,
        },
      });
    }
  },
};

