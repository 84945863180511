/*** IMPORTS FROM imports-loader ***/
var define = false;

/*
|
| Importing Libs 
|------------------
*/
import gsap from "gsap";
import CustomEase from "@lib/gsap-pro/CustomEase";
import ScrollTrigger from "gsap/ScrollTrigger";
import ScrollToPlugin from "gsap/ScrollToPlugin";
import Accordion from "accordion-js";
import "accordion-js/dist/accordion.min.css";
import Swiper, { Navigation, Thumbs } from "swiper";
import "swiper/swiper-bundle.css";
import LazyLoad from "vanilla-lazyload";
import L from "leaflet";
import "leaflet/dist/leaflet.css";
import html2pdf from "html2pdf.js";

// console.log(L);

Swiper.use([Navigation, Thumbs]);

gsap.registerPlugin(ScrollTrigger);
gsap.registerPlugin(CustomEase);
gsap.registerPlugin(ScrollToPlugin);

/*
|
| Importing Components
|-----------------------
*/
//import CookieManager from "@components/cookies";
import customGoogleMap from "@components/google-map.js";
import customAccordion from "@components/accordion.js";
import map from "@components/map.js";

/*
|
| Importing Utils
|-------------------
*/
// import Config from '@utils/config.js';
import Router from "@utils/router.js";

/*
|
| Importing App files
|----------------------
*/
import * as app from "@components/global.js";
import main from "@pages/main.js";
import home from "@pages/home.js";
import news from "@pages/archive-news.js";
import article from "@pages/single-news.js";
import projects from "@pages/archive-projects.js";
import contact from "@pages/contact.js";
import equipe from "@pages/equipe.js";
import offre from "@pages/single-offre.js";

/*
|
| Routing
|----------
*/
const routes = new Router([
  {
    file: main,
    dependencies: [
      app,
      gsap,
      ScrollTrigger,
      CustomEase,
      LazyLoad,
      //CookieManager,
      Accordion,
      L,
    ],
  },
  {
    file: home,
    dependencies: [app, gsap],
    resolve: "#page-home",
  },
  {
    file: news,
    dependencies: [app],
    resolve: "#archive-news",
  },
  {
    file: article,
    dependencies: [app, gsap, ScrollTrigger, Swiper, Navigation],
    resolve: "#single-article",
  },
  {
    file: projects,
    dependencies: [app],
    resolve: "#archive-projects",
  },
  {
    file: contact,
    dependencies: [app, gsap, ScrollTrigger, customGoogleMap],
    resolve: "#page-contact",
  },
  {
    file: customAccordion,
    dependencies: [app],
  },
  {
    file: equipe,
    dependencies: [app, gsap, Swiper, Navigation],
    resolve: "#page-equipe",
  },
  {
    file: offre,
    dependencies: [app, gsap, Swiper, Navigation, Thumbs, html2pdf],
    resolve: "#single-offre",
  },
  {
    file: map,
    dependencies: [app, L],
  },
]);

/*
|
| Run
|------
*/
(($) => {
  routes.load();
})(jQuery);

