/*** IMPORTS FROM imports-loader ***/
var define = false;

// import Accordion from "accordion-js";
// import "accordion-js/dist/accordion.min.css";

export default {
  init: (app) => {
    var menu_link = document.querySelectorAll(
      "#nav-fullscreen .menu-item-has-children"
    );

    for (let i = 0; i < menu_link.length; i++) {
      var menu_link_tag = menu_link[i].querySelector("a");
      menu_link_tag.addEventListener("click", function (e) {
        e.preventDefault();
        var parentDropdown = this.nextElementSibling;
        var childDropdown = parentDropdown.firstChild;
        for (let j = 0; j < menu_link.length; j++) {
          var header_transition =
            menu_link[j].querySelector(".header_transition");
          if (menu_link[j] != menu_link[i]) {
            menu_link[j].classList.remove("active");
            header_transition.style.maxHeight = "0px";
          }
        }
        menu_link[i].classList.toggle("active");

        if (menu_link[i].classList.contains("active")) {
          parentDropdown.style.maxHeight = `${childDropdown.offsetHeight}px`;
        } else {
          parentDropdown.style.maxHeight = "0px";
        }
      });
    }
  },
};

