/*** IMPORTS FROM imports-loader ***/
var define = false;

export default {
  init: (app, L) => {
    let mapBox = document.querySelector("#map");
    if ( mapBox ) {
      const long = parseFloat(mapBox.dataset.long);
      const lat = parseFloat(mapBox.dataset.lat);

      if (!isNaN(lat) && !isNaN(long)) {
        var map = L.map(mapBox, {
          center: [lat, long],
          zoom: 14,
        });
        L.tileLayer("https://tile.openstreetmap.org/{z}/{x}/{y}.png", {
          maxZoom: 19,
          attribution:
            '&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>',
        }).addTo(map);
        var circle = L.circle([lat, long], {
          color: "#3E9AB2",
          fillColor: "#3E9AB2",
          fillOpacity: 0.5,
          radius: 350,
        }).addTo(map);
      }
    }
  },
};

