/*** IMPORTS FROM imports-loader ***/
var define = false;

export default {
  init: (app, gsap, ScrollTrigger, Swiper) => {
    /*
        |
        | Constants
        |------------
        */
    const $window = $(window),
      $progress = $("#progress-line"),
      $trigger = $("#trigger-share"),
      $shareContainer = $(".share-container");
    /*

    /*
        |
        | Share container
        |------------
        */
    $window.on("load", function () {
      var $endmarker = $trigger.height() - 120;

      ScrollTrigger.matchMedia({
        "(min-width: 48rem)": function () {
          gsap.to($shareContainer, {
            scrollTrigger: {
              trigger: $shareContainer,
              pin: true,
              pinSpacing: false,
              start: "top 120",
              end: () => "+=" + $endmarker,
              scrub: 1,
            },
          });
        },
      });
    });

    /* swiper */

    const swiper = new Swiper(".swiper", {
      slidesPerView: 1,
      spaceBetween: 30,
      // loop: true,
      breakpoints: {
        1024: {
          slidesPerView: 3,
        },
      },
    });
  },
};

