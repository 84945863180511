/*** IMPORTS FROM imports-loader ***/
var define = false;

export default {
    init: (app, gsap, ScrollTrigger, customGoogleMap) => {
        /*
        |
        | Constants
        |------------
        */
        const
            $window = $(window),
            $map = $('#map')
            ;


        /*
        |
        | initGoogleMap
        |----------------
        |
        */
        function mapInview() {

            $.each($map, function () {
                var $map = $(this)

                // var lazyContainer = $map.parent()
                // lazyContainer.addClass('is-load')

                var map = new customGoogleMap($map, {
                    'markers': $map.find('.marker'),
                    'zoom': 16
                })
            })
        }

        ScrollTrigger.create({
            trigger: $map,
            onEnter: mapInview,
            onLeaveBack: self => self.disable()
        });

    }
}
