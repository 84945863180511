/*** IMPORTS FROM imports-loader ***/
var define = false;

export default {
  init: (app, gsap) => {
    // console.log(gsap);
    /*
        |
        | Constants
        |------------
        */
    // const
    //     $body = $('body')
    //     ;

    var coll = document.getElementsByClassName("collapsible");
    for (let i = 0; i < coll.length; i++) {
      coll[i].addEventListener("click", function () {
        // if (coll[i] != this) {
        //   coll[i].classList.remove("active");
        // }
        this.classList.toggle("active");
        var maxHeightParent = this.nextElementSibling;
        var contentChild = this.nextElementSibling.firstChild;

        if (this.classList.contains("active")) {
          maxHeightParent.style.maxHeight = `${contentChild.offsetHeight}px`;
        } else {
          maxHeightParent.style.maxHeight = "0px";
        }
      });
    }
  },
};

